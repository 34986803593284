import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { localeCodes, parseLanguage } from '@pcid/locale-utils';
import Button from '../button';

const LanguageToggle = ({ locale, changeLocale, className }) => {
	const language = parseLanguage(locale);
	const oppositeLanguage = language === 'en' ? localeCodes.FR : localeCodes.EN;
	return (
		<Button
			type="button"
			theme="link"
			id="language-toggle"
			className={classNames('language-toggle', className)}
			ariaLabel="layout.footer.languageToggle"
			onClick={() => changeLocale(oppositeLanguage)}
			lang={oppositeLanguage}
		>
			{ Object.keys(localeCodes).sort().map((key) => (
				<span
					className={classNames(
						'language-toggle__option',
						language === key.toLowerCase() && 'language-toggle__option--active'
					)}
					key={key}
					aria-hidden
				>
					{key}
				</span>
			)) }
		</Button>
	);
};

LanguageToggle.propTypes = {
	changeLocale: PropTypes.func.isRequired,
	locale: PropTypes.string.isRequired,
	className: PropTypes.string,
};

export default LanguageToggle;
