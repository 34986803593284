import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import getFooterLinks from '@pcid/utils/get-footer-links';
import { AutoFormattedMessage } from '@pcid/string-utils';
import { getRelyingPartyStorage } from '@pcid/storage-utils';
import Link from '@pcid/link';

import LanguageToggle from '../language-toggle';
import { withLocale } from '../locale-provider';

const Footer = ({
	locale,
	setLocale,
	className,
	links,
}) => {
	const footerLinks = links || getFooterLinks(locale, getRelyingPartyStorage().get() || undefined);
	return (
		<footer className={classNames('footer', className)}>
			<div className="footer__section">
				<ul className="footer__help-and-support-link-list">
					{
						Object.keys(footerLinks).map((key) => footerLinks[key] && (
							<li className="footer__help-and-support-link" key={key}>
								<Link
									to={footerLinks[key]}
									target="_blank"
									rel="noopener noreferrer"
								>
									<AutoFormattedMessage id={`layout.footer.link.${key}`} />
								</Link>
							</li>
						))
					}
				</ul>
				<div className="footer__copyright">
					<AutoFormattedMessage id="layout.footer.allRights" values={{ year: new Date().getFullYear() }} />
				</div>
				<LanguageToggle
					className="footer__language-toggle"
					changeLocale={setLocale}
					locale={locale}
				/>
			</div>
		</footer>
	);
};

Footer.propTypes = {
	locale: PropTypes.string,
	setLocale: PropTypes.func.isRequired,
	className: PropTypes.string,
	links: PropTypes.object,
};

export default withLocale(Footer);
