/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Head from 'next/head';

import { AutoFormattedMessage, autoFormatMessage } from '@pcid/string-utils';
import { getRelyingPartyStorage } from '@pcid/storage-utils';
import Link from '@pcid/link';
import Sprite from '@pcid/sprite';
import { withGlobalNotification } from '../state-context';
import GlobalNotification from '../global-notification';

import Footer from '../footer';

const propTypes = {
	className: PropTypes.string,
	showIcon: PropTypes.bool,
};

export const Heading = ({ className, showIcon, ...props }) => (
	<div className={classNames('layout__heading', className)}>
		{showIcon && (
			<Sprite
				group="banners"
				symbol="pc-id"
				alt="PCid Logo"
				className="layout__pcid-logo"
			/>
		)}
		<h2 tabIndex="0">
			<AutoFormattedMessage {...props} />
		</h2>
	</div>
);

Heading.propTypes = propTypes;

export const Message = ({ className, ...props }) => (
	<div
		className={classNames('layout__message', className)}
		tabIndex="0"
	>
		<AutoFormattedMessage {...props} />
	</div>
);

Message.propTypes = propTypes;

export const SecondaryCTA = ({ className, children }) => (
	<div className={classNames('layout__secondary-cta', className)}>
		{children}
	</div>
);

SecondaryCTA.propTypes = { ...propTypes, children: PropTypes.node.isRequired };

const Layout = ({
	children,
	title,
	className,
	relyingParty = getRelyingPartyStorage().get(),
	showNotification,
	notificationPosition,
	backLink,
	backButtonTrackingAnalytics,
	convergenceBanner,
	convergenceBannerPosition,
}) => (
	<div className={classNames('layout-container', (notificationPosition === 'above' && showNotification) ? 'layout-container--top-margin' : '')}>
		<div className="layout__content">
			{relyingParty !== 'account-management' ? null : (
				<Head>
					<title>
						{autoFormatMessage({ id: title, values: { _case: 'title' } })}
					</title>
				</Head>
			)}
			{showNotification && (
				<GlobalNotification />
			)}
			{backLink
				? (
					<div className="layout__back-button">
						<Link
							className="layout__back-button-link"
							id="back"
							onClick={backButtonTrackingAnalytics}
							to={backLink}
						>
							<Sprite
								className="layout__back-button-icon"
								symbol="chevron-left-squared-edge"
								group="icons"
							/>
							<AutoFormattedMessage id="common.backButton" />
						</Link>
					</div>
				)
				: (
					''
				)}

			{['top', 'left'].includes(convergenceBannerPosition) && convergenceBanner}

			<div
				role="main"
				className={classNames(backLink ? 'layout__main' : 'layout',
					convergenceBannerPosition === 'left' ? 'layout__with-left-banner' : '',
					className)}
			>
				{children}
			</div>

			{convergenceBannerPosition === 'bottom' && convergenceBanner}

		</div>
		<Footer className="layout__footer" />
	</div>
);

Layout.propTypes = {
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]).isRequired,
	className: PropTypes.string,
	children: PropTypes.node,
	showNotification: PropTypes.bool,
	notificationPosition: PropTypes.oneOf(['above', 'below', '']),
	relyingParty: PropTypes.string,
	backLink: PropTypes.string,
	backButtonTrackingAnalytics: PropTypes.func,
	convergenceBanner: PropTypes.element,
	convergenceBannerPosition: PropTypes.string,
};

export default withGlobalNotification(Layout);
