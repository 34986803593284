import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Sprite from '@pcid/sprite';
import { autoFormatMessage, AutoFormattedMessage } from '@pcid/string-utils';
import Button from '../button';
import { withGlobalNotification } from '../state-context';

const GlobalNotification = ({ text, status, dismissNotification, notificationPosition = 'below' }) => {
	const notificationText = (typeof text === 'string')
		? autoFormatMessage(text)
		: AutoFormattedMessage(text);

	return (
		<div
			className={classNames(['notification', `notification--${status}`, `notification--${notificationPosition}`])}
			role="alert"
		>
			<div className="notification-content">
				<div className="notification-content__icon">
					<Sprite
						group="icons"
						symbol={status}
						className={classNames(['notification__icon', 'notification__icon--status'])}
					/>
				</div>
				<div className="notification-content__text">
					{text && notificationText}
				</div>
			</div>
			{['success', 'error'].includes(status) && (
				<div
					className="notification-close"
				>
					<div className="notification-close__icon">
						<Button
							display="inline"
							theme="none"
							onClick={dismissNotification}
							aria-label={autoFormatMessage('page.accountManagement.dismissNotification')}
						>
							<Sprite
								group="icons"
								symbol={status === 'success' ? 'close-green' : 'close'}
								className={classNames(['notification__icon', 'notification__icon--close'])}
							/>
						</Button>
					</div>
					<div className={classNames(['notification-close__rectangle', `notification-close__rectangle--${status}`])} />
				</div>
			)}
		</div>
	);
};

GlobalNotification.propTypes = {
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	status: PropTypes.string,
	notificationPosition: PropTypes.oneOf(['above', 'below']),
	dismissNotification: PropTypes.func,
};

export default withGlobalNotification(GlobalNotification);
