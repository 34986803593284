import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { autoFormatMessage } from '@pcid/string-utils';

import LoadingAnimation from '../loading-animation';

/**
 * Button renders a standardized button with styles, type, and disabled props.
 *
 * @prop {string} ariaLabel - Set the html 'aria-label' attribute.
 * @prop {string|array} className - Append extra classes to the button.
 * @prop {string} type - Set the html `type` attribute. Defaults to `button`,
 *       other common option is `submit`.
 * @prop {string} display - Defaults to `inline`, other valid values are `block`
 *       and `small`.
 * @prop {string} theme - The css theme for the button. See Button.propTypes for
 *       allowed strings.
 * @prop {bool} disabled - Set the html 'disabled' attribute.
 * @prop {bool} submitting - Set the html 'disabled' attribute.
 * @prop {any} children - Contents to be wrapped within Button.
 * @prop {object} intl - Internationalization.
 * @prop {bool} focusOnPropUpdate - Apply the focus on the button when new
 *       props are received.  This should only be used if it can be guarenteed
 *       that only one button with this property is being re-rendered at the
 *       same time, since only one button can have focus at a time.
 * @prop {bool} delayRefocusOnClick - when this item is clicked, then the focus
 *       should be taken away and after a small delay, put back.  This is to
 *       force screenreaders to reread the button text, say, if the innerHTML
 *       changes.
 */
/* eslint-disable */
export default class Button extends React.Component {
	static propTypes = {
		className: PropTypes.string,
		type: PropTypes.string,
		display: PropTypes.oneOf(['inline', 'block', 'small']),
		theme: PropTypes.oneOf([
			'dark',
			'transparent',
			'white',
			'transparent-white',
			'red',
			'link',
			'link-grey',
			'none',
			'visible-only-onfocus',
		]),
		disabled: PropTypes.bool,
		children: PropTypes.node.isRequired,
		ariaLabel: PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.object,
		]),
		delayRefocusOnClick: PropTypes.bool,
		submitting: PropTypes.bool,
		onClick: PropTypes.func,
		hasAriaLiveText: PropTypes.bool,
	};

	static defaultProps = {
		type: 'button',
		display: 'inline',
		theme: 'none',
		disabled: false,
		delayRefocusOnClick: false,
		submitting: false,
		hasAriaLiveText: false,
	}

	constructor(props) {
		super(props);
		this.ref = React.createRef();
		this.focusTimeout = null;
	}

	componentWillUnmount() {
		clearTimeout(this.focusTimeout);
	}

	clickHandler = (e) => {
		const { onClick, delayRefocusOnClick } = this.props;

		if (delayRefocusOnClick && this.focusWhenDelayed) {
			this.focusWhenDelayed.focus();
			this.focusTimeout = setTimeout(() => {
				this.ref.focus();
			}, 300);
		}

		if (onClick) onClick(e);
	}

	render() {
		const {
			children,
			ariaLabel,
			className,
			type,
			display = 'inline',
			theme,
			submitting,
			disabled,
			hasAriaLiveText,
			delayRefocusOnClick,
			...buttonProps
		} = this.props;

		const base = (theme.slice(0, 4) !== 'link' && theme !== 'none');
		const classes = classNames(
			'button',
			`button--${display}`,
			type !== 'button' && `button--${type}`,
			base && 'button--theme-base',
			theme && `button--theme-${theme}`,
			theme && theme.slice(0, 4) === 'link' && `link link--${display}`,
			submitting && 'button--submitting',
			className
		);

		const ariaLabelText = ariaLabel && autoFormatMessage(ariaLabel);

		/* eslint-disable react/button-has-type */
		const buttonMarkup = (
			<button
				className={classes}
				type={type}
				disabled={submitting || disabled}
				aria-label={ariaLabelText}
				aria-live={hasAriaLiveText ? 'assertive' : null}
				aria-disabled={disabled.toString()}
				role={hasAriaLiveText ? 'alert' : null}
				ref={this.ref}
				{...buttonProps}
				onClick={this.clickHandler}
			>
				{submitting ? <LoadingAnimation /> : children}
			</button>
		);

		let template;

		if (delayRefocusOnClick) {
			const containerClasses = classNames(
				'button__container',
				`${className}-container`
			);
			template = (
				<div className={containerClasses}>
					{buttonMarkup}
					<span
						ref={
							(elem) => {
								this.focusWhenDelayed = elem;
							}
						}
						tabIndex="-1"
						className="visually-hidden button__focus-before-delay"
					/>
				</div>
			);
		} else {
			template = buttonMarkup;
		}
		return template;
	}
}
